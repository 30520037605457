.meta-list {
    border-radius: 14px;
    background-color: #3D4152;
    position: relative;
    padding: 2rem;
    &:after,
    &:before {
        content: '';
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 30px;
        border-radius: 15px;
        background-color: #fff;
    }
    &:after {
        right: -15px;
    }
    &:before {
        left: -15px;
    }
}